<template>
  <div>
    <div class='company-sidebar__header'>
      <h1><a :href='company_url'>{{company_name}}</a></h1>
      <template v-if='can_add'>
        <dropdown-component>
          <template v-slot:trigger><button class="dropdown-toggle"><i class='fal fa-plus'></i></button></template>
          <template v-slot:content>
            <div class='dropdown__header'>
              <h3>Quick Add</h3>
            </div>
            <a class="dropdown__item" v-for='(item, index) in add_items' :href="item.url">
              <div class='asset-icon' :style="{background: item.color}">
                <i :class='item.icon' :style="{color: item.icon_color}"></i>
              </div><span>{{item.name}}</span>
            </a>
          </template>
        </dropdown-component>
      </template>
    </div>

    <nav>
      <a class='company-sidebar__a' v-tooltip.right='core.name' :href="core.url" v-for='(core, index) in cores' :class='core.selected'>
        <div class="asset-icon">
          <i :class="core.i"></i>
        </div>
        <span class="company-sidebar__name">{{core.name}}</span>
        <span class="count" v-if='core.count !== false'>{{core.count}}</span>
      </a>
      <template v-if='passwords_included'>
        <a  :class='{"company-sidebar__a": true, "selected": (passwords_selected && (passwords_folder_selected == 0))}' v-tooltip.right='`All Passwords`' :href="passwords_url">
          <div class="asset-icon">
            <i class="fas fa-key"></i>
          </div>
          <span class="company-sidebar__name">Passwords</span>
          <span class="count">{{passwords_count}}</span>
        </a>
        <template v-if='passwords_selected'>
          <a v-tooltip.right='`${password_folder.name} in Passwords`' :href="password_folder.url" v-for='(password_folder, index) in password_folders' :class='{"company-sidebar__a company-sidebar__folder-item": true, "selected": (passwords_selected && (passwords_folder_selected == password_folder.id))}'>
            <div class="asset-icon">
              <i class='fas fa-folder'></i>
            </div>
            <span class="company-sidebar__name">{{password_folder.name}}</span>
            <span class="count">{{password_folder.count}}</span>
          </a>
        </template>
      </template>

      <a class='company-sidebar__a' v-tooltip.right='tool.name'  v-if='tool.name != "Passwords" ':href="tool.url" v-for='(tool, index) in tools' :class='tool.selected'>
        <div class="asset-icon">
          <i :class="tool.i"></i>
        </div>
        <span class="company-sidebar__name">{{tool.name}}</span>
        <span class="count" v-if='tool.count !== false'>{{tool.count}}</span>
      </a>
    </nav>

    <div v-if='data.length > 0'>
      <h2 class='company-sidebar__title'>Custom</h2>
      <div href='#' v-for='folder in folders' class='company-sidebar__folder'>
        <a href='#' class='company-sidebar__folder__a' @click.prevent.stop='toggleFolder(folder.id)' v-tooltip.right='folder.name'>
          <div class='asset-icon'>
            <i class='fas fa-folder' v-if='isFolderHidden(folder.id)'></i>
            <i class='fas fa-folder-open' v-if='!isFolderHidden(folder.id)'></i>
          </div>
          <span class="company-sidebar__name">{{folder.name }}</span>
          <!-- <i v-if='!isFolderHidden(folder.id)' class='fal fa-chevron-up folder-drop-icon'></i>
          <i v-if='isFolderHidden(folder.id)' class='fal fa-chevron-down folder-drop-icon'></i> -->
        </a>
        <a :href="d.url" v-tooltip.right='`${d.name} in ${folder.name}`' v-if='showSidebarChild(folder.id, d.sidebar_folder_id)' v-for='(d, index) in filteredData' :class='{"selected": d.selected, "company-sidebar__folder-item": true, "company-sidebar__a": true}'>
          <div class="asset-icon" :style="{background: d.color}">
            <i :class="d.icon" :style="{color: d.icon_color}"></i>
          </div>
          <span class="company-sidebar__name">{{d.name}}</span>
          <span class="count">{{d.count}}</span>
        </a>
      </div>

      <a class='company-sidebar__a' v-tooltip.right='d.name' :href="d.url" v-if='!d.sidebar_folder_id' v-for='(d, index) in filteredData' :class='{"selected": d.selected}'>
        <div class="asset-icon" :style="{background: d.color}">
          <i :class="d.icon" :style="{color: d.icon_color}"></i>
        </div>
        <span class="company-sidebar__name">{{d.name}}</span>
        <span class="count">{{d.count}}</span>
      </a>


    </div>
    <div :class='{"company-sidebar__buttons": true, "company-sidebar__buttons--full": data.length == 0}'>
      <a href='#' v-if='sidebarLevel == 0' @click.prevent.stop='resizeSidebar(1)'><i class="fas fa-angle-double-left"></i><span>Collapse</span></a>
      <a href='#' v-if='sidebarLevel == 1' @click.prevent.stop='resizeSidebar(2)'><i class="fas fa-angle-double-right"></i><span>Expand</span></a>
      <a href='#' v-if='sidebarLevel == 2' @click.prevent.stop='resizeSidebar(0)'><i class="fas fa-angle-double-left"></i><span>Collapse</span></a>
      <template v-if='data.length > 0'>
        <a href='#' v-if='!hideEmpty' title='Hide Blank Custom Assets' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye-slash'></i><span>Hide Blank</span></a>
        <a href='#' v-if='hideEmpty' title='Show Blank Custom Assets' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye'></i><span>Show Blank</span></a>
      </template>
    </div>
  </div>
</template>

<script>
import FloatingVue from 'floating-vue';
import DropdownComponent from './DropdownComponent.vue';
export default {
  props: ["can_add", "company_name", "company_url", "add_items", "cores", "tools", "data", "folders", "password_folders", "passwords_included", "passwords_url", "passwords_count", "passwords_selected", "passwords_folder_selected"],
  data () {
    return {
      sidebarLevel: 0,
      hideEmpty: false,
      hides: []
    }
  },
  components: {
    DropdownComponent
  },
  mounted () {


    FloatingVue.options.themes.tooltip.disabled = true;

    if (localStorage.getItem('huduHideEmptySidebarAssets')) {
      try {
        this.hideEmpty = true;
      } catch(e) {

      }
    }
    if (localStorage.getItem('huduSidebarFolderHides')) {
      try {
        this.hides = JSON.parse(localStorage.getItem('huduSidebarFolderHides'));
      } catch(e) {

      }
    }
    if (localStorage.getItem('huduSidebarResizeLevel')) {
      try {
        this.sidebarLevel = localStorage.getItem('huduSidebarResizeLevel')
        this.calculateSidebarWidth(this.sidebarLevel)
      } catch(e) {

      }
    }
  },
  methods: {
    resizeSidebar (level) {
      this.sidebarLevel = level
      localStorage.setItem('huduSidebarResizeLevel', level);

      this.calculateSidebarWidth(level)
    },
    calculateSidebarWidth(level) {
      var element = document.getElementById('company-sidebar');
      let root = document.documentElement;

      if (level == 1) {
        FloatingVue.options.themes.tooltip.disabled = false;
        element.classList.add("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '66px');
      } else if (level == 2) {
        FloatingVue.options.themes.tooltip.disabled = true;
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '380px');
      } else {
        FloatingVue.options.themes.tooltip.disabled = true;
        element.classList.remove("company-sidebar--skinny");
        root.style.setProperty('--sidebarwidth', '235px');
      }
    },
    showSidebarChild(folder_id, parent_id) {
      if ((!this.isFolderHidden(folder_id)) && (parent_id === folder_id))
        return true
      return false
    },
    isFolderHidden(id) {
      if (this.hides.includes(id))
        return true;
      return false
    },
    toggleFolder(id) {
      if(this.isFolderHidden(id)) {
        const index = this.hides.indexOf(id);
        if (index > -1) {
          this.hides.splice(index, 1);
          localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
        }
      } else {
        this.hides.push(id);
        localStorage.setItem('huduSidebarFolderHides', JSON.stringify(this.hides));
      }
    },
    toggleEmpty () {
      this.hideEmpty = !this.hideEmpty;

      if (this.hideEmpty === true) {
        localStorage.hide_assets = true
        localStorage.setItem('huduHideEmptySidebarAssets', true);
      } else {
        localStorage.removeItem('huduHideEmptySidebarAssets')
      }

      // if (this.hideEmpty === true) {

      //   localStorage.setItem('huduHideEmptySidebarAssets', false);
      // } else {
      //   this.hideEmpty = true;
      //   localStorage.setItem('huduHideEmptySidebarAssets', true);
      // }
    }
  },
  computed: {
    filteredData() {
      return this.hideEmpty ? this.data.filter(item => !(item.count == 0)) : this.data
    },
    filteredFolderData() {
      return this.filteredData.filter(item => item.sidebar_folder_id == this.activeFolderId)
    }
  }
}
</script>
